import { Injectable } from '@angular/core';
import { ProductSearchModel } from '../securehome/picture-search/picture-search.component';

@Injectable({
  providedIn: 'root',
})
export class SharedSearchService {
  searchParams: ProductSearchModel = {};

  constructor() {}

  setSearchParams(params: ProductSearchModel) {
    this.searchParams = params;
  }

  setSearchParamsPage(searchPage: number) {
    this.searchParams.page = searchPage;
  }

  setSearchParamsPageSize(searchPageSize: number) {
    this.searchParams.pageSize = searchPageSize;
  }

  getSearchParamsPageSize() {
    return this.searchParams.pageSize;
  }

  getSearchParamsPage() {
    return this.searchParams.page;
  }

  getSearchParams() {
    return this.searchParams;
  }
}
