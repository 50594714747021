import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public isLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject$.asObservable();
  }

  private errorSubject$ = new BehaviorSubject<{
    error: boolean;
    message: string;
  }>({ error: false, message: '' });
  public error$: Observable<{ error: boolean; message: string }> =
    this.errorSubject$.asObservable();

  setErrorStatus(error: boolean, message: string = '') {
    this.errorSubject$.next({ error, message });
  }
}
