import { Component, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { SearchResult } from 'src/app/search-result';
import { CheckboxListComponent } from '../checkbox-list/checkbox-list.component';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { PictureSearchComponent } from '../picture-search/picture-search.component';

@Component({
  selector: 'app-picture-expansion-panel',
  templateUrl: './picture-expansion-panel.component.html',
  styleUrls: ['./picture-expansion-panel.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    PictureSearchComponent,
    MatCardModule,
    DateRangePickerComponent,
    CheckboxListComponent,
  ],
})
export class PictureExpansionPanelComponent {
  @ViewChild('searchPanel') searchPanel!: MatExpansionPanel;
  @ViewChild('resultPanel') resultPanel!: MatExpansionPanel;
  isSearchPanelExpanded = true;
  isResultPanelExpanded = false;

  onSearchClicked() {
    this.isResultPanelExpanded = false;
    this.isSearchPanelExpanded = false;
    this.isSearchPanelExpanded = true;
    this.isResultPanelExpanded = true;
  }

  onClearClicked() {
    this.isResultPanelExpanded = false;
  }

  searchResults!: SearchResult;

  onSearchResultsChanged(results: SearchResult) {
    this.searchResults = results;
  }
}
