export class ProductSearch {
  public workstation: string;
  public file: string;
  public carline: string;
  public ordertype: string;
  public orderident: string;
  public originalFilename: string;
  public endDate: string;
  public startDate: string;
  public selection: string;

  constructor(
    workstation: string,
    file: string,
    carline: string,
    ordertype: string,
    orderident: string,
    endDate: string,
    startDate: string,
    originalFilename: string,
    selection: string,
  ) {
    this.workstation = workstation;
    this.file = file;
    this.carline = carline;
    this.ordertype = ordertype;
    this.orderident = orderident;
    this.endDate = endDate;
    this.startDate = startDate;
    this.originalFilename = originalFilename;
    this.selection = selection;
  }
}
