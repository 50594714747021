import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface WorkstationResponse {
  items: any[]; // Adjust the type based on your actual response
}

@Injectable({
  providedIn: 'root',
})
export class WorkstationService {
  constructor(private http: HttpClient) {}

  fetchWorkstations(carlineId: string): Observable<WorkstationResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('carline', carlineId);

    const apiUrl = environment.restApiUrl + '/images/getworkstation';
    return this.http.get<WorkstationResponse>(apiUrl, { params: queryParams });
  }
}
