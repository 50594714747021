<mat-card class="full-width-card">
  <mat-card-header>
    <mat-card-title>Download Center</mat-card-title>
    <button
      mat-icon-button
      (click)="refresh()"
      [matTooltip]="'Refresh the page'"
      class="page-button"
    >
      <mat-icon>refresh</mat-icon>
      <span>Refresh</span>
    </button>
  </mat-card-header>
  <mat-card-content class="custom-card-content">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
    </mat-form-field>
    <mat-table [dataSource]="dataSource" matSort>
      <!-- Export Type Column -->
      <ng-container matColumnDef="ExportType">
        <mat-header-cell *matHeaderCellDef> Export Type </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.pk }} </mat-cell>
      </ng-container>

      <!-- From Column -->
      <ng-container matColumnDef="From">
        <mat-header-cell *matHeaderCellDef> From </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.from }} </mat-cell>
      </ng-container>

      <!-- To Column -->
      <ng-container matColumnDef="To">
        <mat-header-cell *matHeaderCellDef> To </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.to }} </mat-cell>
      </ng-container>

      <!-- Started By Column -->
      <ng-container matColumnDef="StartedBy">
        <mat-header-cell *matHeaderCellDef> Started By </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.startedBy }} </mat-cell>
      </ng-container>

      <!-- Started At Column -->
      <ng-container matColumnDef="startedAt">
        <mat-header-cell *matHeaderCellDef> Started At </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.startedAt }} </mat-cell>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Status" [style.width.px]="50">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <app-progress-bar [status]="row.status"></app-progress-bar>
        </mat-cell>
      </ng-container>

      <!-- Size Column -->
      <ng-container matColumnDef="Download Size" [style.width.px]="250">
        <mat-header-cell *matHeaderCellDef> Download Size </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.size }} </mat-cell>
      </ng-container>

      <!-- Download Column -->
      <ng-container matColumnDef="Download" [style.width.px]="100">
        <mat-header-cell *matHeaderCellDef> Download </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.status !== 'CANCEL'">
            <ng-container *ngIf="row.bucketKey; else refreshIcon">
              <button
                class="custom-icon-button download-button"
                mat-icon-button
                (click)="downloadFile(row)"
              >
                <mat-icon>cloud_download</mat-icon>
              </button>
            </ng-container>
            <ng-template #refreshIcon>
              <button
                mat-icon-button
                [matTooltip]="'Refresh the page'"
                class="refresh-button"
              >
                <mat-icon>info</mat-icon>
              </button>
            </ng-template>
          </ng-container>
        </mat-cell>
      </ng-container>
      <!-- Deleting Column -->
      <ng-container matColumnDef="Delete" [style.width.px]="50">
        <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.bucketKey; else refreshIcon">
            <button
              class="custom-icon-button download-button"
              mat-icon-button
              [matTooltip]="'delete the download'"
              (click)="deleteDownloadFile(row)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
          <ng-template #refreshIcon>
            <button
              mat-icon-button
              [matTooltip]="'cancel the download'"
              class="cancel-button"
              (click)="cancelDownloadFile(row)"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-template>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card-content>
</mat-card>
