/* eslint-disable prettier/prettier */
// picture-search.component.ts
import { CommonModule, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { catchError, debounceTime, take } from 'rxjs';
import { SearchResult } from 'src/app/search-result';
import { CarlineService } from 'src/app/services/carline.service';
import { CheckboxService } from 'src/app/services/checkbox-service.service';
import { ColumnWidthService } from 'src/app/services/columnwidth.service';
import { DataPaginatorService } from 'src/app/services/data-paginator.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { GridColumnsService } from 'src/app/services/grid-columns.service';
import { OrderTypeService } from 'src/app/services/ordertype.service';
import { SharedSearchService } from 'src/app/services/shared-search-service.service';
import { WorkstationService } from 'src/app/services/workstation.service';
import { AlertComponent } from '../../alert-info/alert-info.component';
import { AlertInfoService } from '../../alert-info/service/alert-info.service';
import { AlertTypeEnum } from '../../alert-info/type/AlertType.enum';
import { ProductSearch } from '../../productsearch';
import { SearchService } from '../../services/search-service.service';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';

export interface ProductSearchModel {
  file?: string;
  ordertype?: string;
  carline?: string;
  workstation?: string;
  orderident?: string;
  originalFilename?: string;
  endDate?: string;
  startDate?: string;
  selection?: string;
  page?: number;
  pageSize?: number;
}

@Component({
  selector: 'app-picture-search',
  templateUrl: './picture-search.component.html',
  styleUrls: ['./picture-search.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    DateRangePickerComponent,
    FormsModule,
    AlertComponent,
    NgIf,
    CommonModule,
    MatIconModule,
    MatGridListModule,
    MatTooltipModule,
    MatSelectModule, // Import MatSelectModule
    MatOptionModule,
    MatRadioModule,
  ],
})
export class PictureSearchComponent implements OnInit{
  searchParams: ProductSearchModel = {};
  pageSize: number = 25;
  gridCols!: number
  model: ProductSearch;
  searchResults!: SearchResult;
  startDate?: string;
  endDate?: string;
  errorMessage!: string;
  @Output() searchResultsChanged = new EventEmitter<SearchResult>();
  @Output() searchClicked: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(DateRangePickerComponent)
  dataRangeComponent!: DateRangePickerComponent;
  checkboxListComponent: any;
  isLoading = false;
  ordertypes: any[] = [];
  carline: any[] = [];
  workstations: any[] = [];
  

  handleDateRangeSelected(dateRange: Date[] | undefined): void {
    if (dateRange) {
      this.startDate = dateRange[0]?.toISOString();
      this.endDate = dateRange[1]?.toISOString();
    } else {
      // Handle the case when dateRange is undefined
      this.startDate = undefined;
      this.endDate = undefined;
    }
  }

  constructor(
    private searchService: SearchService,
    private alerInfoService: AlertInfoService,
    private sharedSearchService: SharedSearchService,
    private checkboxService: CheckboxService,
    private columnWidthService: ColumnWidthService,
    public dataPaginatorService: DataPaginatorService,
    private carlineService: CarlineService,
    private workstationService: WorkstationService,
    private orderTypeService: OrderTypeService,
    private errorHandlingService: ErrorHandlingService,
    private girdColumnServive: GridColumnsService, 
    
  ) {
    this.model = new ProductSearch('', '', '', '', '', '', '', '', 'images');
  }

  selection: any[] = [
    { id: 'images', name: 'Images' },
    { id: 'html_files', name: 'HTML' },
    { id: 'text_files', name: 'Text' },
    { id: 'csv_files', name: 'CSV' },
    { id: 'default_files', name: 'other file type' },
    // Add more options as needed
  ];

  ngOnInit(): void {
    this.girdColumnServive.gridCols$.subscribe(cols=> {this.gridCols = cols;})
    this.fetchOrderTypes();
    this.fetchCarlineTypes();

     // Fetch order types when component initializes
  }


  showAlert(type: AlertTypeEnum, text: string) {
    this.alerInfoService.setAlert({
      type,
      text,
    });
  }

  onCarlineSelected(carlineId: string) {
    // Upon selecting a carline, fetch corresponding workstations
    this.model.workstation = ''; 
    this.fetchWorkstations(carlineId);
  }


  submitted = false;

  onSubmit() {
    this.submitted = true;
  }

  showFormControls(form: any) {
    return form && form.controls.name && form.controls.name.value;
  }

  search(): void {
    this.searchService
      .search(this.searchParams)
      .pipe(debounceTime(3000), take(1))
      .subscribe(
        (result: any) => {
          console.log(result);
          
  
          // Check if result.body is present
          if (result.body && result.statusCode== 200) {

            this.searchResults = JSON.parse(result.body) as SearchResult;
            this.searchResultsChanged.emit(this.searchResults);
          } else if (result.body && result.statusCode != 200) {
            const bodyObject = JSON.parse(result.body);
            const errorMessage = bodyObject.message;
            this.errorHandlingService.handleError(result.statusCode, 'An internal server error occurred', errorMessage);
          }
          else  {
            // Handle the internal server error
            this.errorHandlingService.handleError( '500', 'Unexpected response:', result);
          }
        }
      );
  }
  
  fetchWorkstations(carlineId: string) {
    this.workstationService.fetchWorkstations(carlineId).pipe(
      catchError((error) =>
        this.errorHandlingService.handleError('500','Error fetching workstations. You can still search with other parameters', error)
      )
    ).subscribe(
      (data) => {
        this.workstations = data.items;  // Assign fetched data to workstations array
      }
    );
  }

  fetchOrderTypes() {
    this.orderTypeService.fetchOrderTypes().pipe(
      catchError((error) =>
        this.errorHandlingService.handleError('500','Error fetching order types. You can still search with other parameters', error)
      )
    ).subscribe(
      (data) => {
        this.ordertypes = data.items;  // Assign fetched data to ordertypes array
      }
    );
  }
  
  fetchCarlineTypes() {
    this.carlineService.fetchCarlineTypes().pipe(
      catchError((error) =>
        this.errorHandlingService.handleError('500','Error fetching carline types. You can still search with other parameters',error)
      )
    ).subscribe(
      (data) => {
        this.carline = data.items;  // Assign fetched data to carline array
      }
    );
  }

  wait(milliseconds: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, milliseconds);
    });
  }
  

    onSearch(): void {
    this.checkboxService.clearSelectedRows();
    this.dataPaginatorService.resetToDefault();
    this.columnWidthService.setDefaultColumnWidth(50);
    this.checkboxListComponent;
    this.resetSearchParams();

    this.searchParams.carline = this.model.carline;
    this.searchParams.workstation = this.model.workstation;
    this.searchParams.orderident = this.model.orderident;
    this.searchParams.ordertype = this.model.ordertype;
    this.searchParams.file = this.model.file;
    this.searchParams.selection = this.model.selection || 'images';
    this.searchParams.originalFilename = this.model.originalFilename;  

    if (this.endDate && this.startDate) {
      this.searchParams.endDate = this.endDate;
      this.searchParams.startDate = this.startDate;
    } else {
      this.searchParams.endDate = undefined;
      this.searchParams.startDate = undefined;
      this.showAlert(
        AlertTypeEnum.warning,
        'ERROR: Please provide the date range',
      );
    }

  const relevantModelValues = Object.entries(this.model)
  .filter(([key, value]) => key !== 'startDate' && key !== 'endDate' && key !== 'selection')
  .map(([key, value]) => value);

    if (
      Object.values(relevantModelValues).some(value => value !== '') || this.startDate || this.endDate
    ) {
      if (
        Object.values(relevantModelValues).some(value => value !== '')
      ) {
      } else {
        this.showAlert(
          AlertTypeEnum.warning,
          'ERROR: Please provide one of the parameters',
        );
      }

      if (
        (Object.values(relevantModelValues).some(value => value !== '')) &&
        this.searchParams.startDate &&
        this.searchParams.endDate
      ) {
        this.sharedSearchService.setSearchParams(this.searchParams);
        this.searchClicked.emit();
        this.search();
      }
    } else {
      this.showAlert(
        AlertTypeEnum.warning,
        'ERROR: Please provide at minimum one parameter and the date range',
      );
    }
  }

  validateDates(startDate: string, endDate: string): boolean {
    // Check if either startDate or endDate is missing or undefined
    if (!startDate || !endDate) {
      return false;
    }

    // Additional validation logic if needed

    return true;
  }

  public clearFilter(): void {
    this.checkboxService.clearSelectedRows();
    this.columnWidthService.setDefaultColumnWidth(50);
    this.resetSearchParams();
    const propertiesToClear: (keyof ProductSearch)[] = [
      'workstation',
      'file',
      'carline',
      'ordertype',
      'orderident',
      'startDate',
      'endDate',
      'originalFilename',
      'selection',
    ];

    for (const prop of propertiesToClear) {
      this.model[prop] = '';
      this.searchParams[prop] = '';
    }
    this.model['selection']= 'images'

    // Clear the date range
    this.startDate = '';
    this.endDate = '';

    if (this.dataRangeComponent) {
      this.dataRangeComponent.clearDateRange();
    }
  }
  

  resetSearchParams(): void {
    this.sharedSearchService.setSearchParamsPage(0);
    this.sharedSearchService.setSearchParamsPageSize(25);
  }
}
