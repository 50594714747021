import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AboutModule } from '@drx/uilib/about';
import { AWSAuthModule } from '@drx/uilib/auth/aws-ui';
import { PublicModule as LibPublicModule } from '@drx/uilib/public';
import { SharedModule } from '../shared/shared.module';
import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public.component';

@NgModule({
  declarations: [PublicComponent],
  imports: [
    AWSAuthModule,
    CommonModule,
    PublicRoutingModule,
    LibPublicModule,
    SharedModule,
    AboutModule,
  ],
})
export class PublicModule {}
