import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // don't use two authorization mechanisms --> download urls for S3 files for example have an auth mechanism already as queryParam
    if (req.url.indexOf('?') !== -1) {
      const queryParams = req.url.split('?')[1];
      if (
        queryParams.includes('AWSAccessKeyId') &&
        queryParams.includes('Signature')
      ) {
        return next.handle(req);
      }
    }

    // Don't add authorization for local URLs
    if (!req.url.startsWith('http')) {
      return next.handle(req);
    }

    return this.getToken().pipe(
      mergeMap((token) => {
        const idToken = token;
        if (idToken) {
          const cloned = req.clone({
            headers: req.headers.set('Authorization', idToken),
          });
          return next.handle(cloned);
        } else {
          return next.handle(req);
        }
      }),
    );
  }

  private getToken(): Observable<any> {
    return from(Auth.currentSession()).pipe(
      map((session: any) => session.getIdToken().getJwtToken()),
    );
  }
}
