import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError } from 'rxjs';
import { CancelFileService } from 'src/app/services/cancel-file.service';
import { DeleteFileService } from 'src/app/services/delete-file.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import {
  DownloadData,
  DownloadService,
} from 'src/app/services/exportcenter.service';
import { PresignedurlHandlerService } from 'src/app/services/presignedurl-handler.service';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';

@Component({
  selector: 'app-download-center',
  templateUrl: './download-center.component.html',
  styleUrls: ['./download-center.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatIconModule,
    ProgressBarComponent,
    CommonModule,
    MatTooltipModule,
  ],
})
export class DownloadCenterComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = [
    'ExportType',
    'From',
    'To',
    'StartedBy',
    'startedAt',
    'Status',
    'Download Size',
    'Download',
    'Delete',
  ];
  dataSource: MatTableDataSource<DownloadData>;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(
    private deleteFileService: DeleteFileService,
    private cancelFileService: CancelFileService,
    private urlHandlerService: PresignedurlHandlerService,
    private downloadService: DownloadService,
    private toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService,
  ) {
    this.dataSource = new MatTableDataSource<DownloadData>([]); // Initialize with an empty array
  }

  ngOnInit() {
    this.fetchDownloadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchDownloadData() {
    this.downloadService.fetchDownloadData().subscribe(
      (data) => {
        this.dataSource.data = data;
      },
      (error) => {
        this.errorHandlingService.handleError(
          '500',
          'Error fetching download data',
          error,
        );
      },
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadFile(row: any) {
    this.getpresignedUrl(row.bucketKey).subscribe((presignedUrl) => {
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.download = row.fileName;
      link.target = '_blank';
      link.click();
    });
  }

  deleteDownloadFile(row: any) {
    this.deleteFileService.deleteDownloadFile(row).subscribe({
      next: () => {
        this.errorHandlingService.handleSuccess(
          'Download deleted successfully',
        );

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      error: (err) => {
        this.errorHandlingService.handleError(
          '500',
          'Error deleting download',
          err,
        );
      },
    });
  }

  cancelDownloadFile(row: any) {
    this.cancelFileService.cancelDownloadFile(row).subscribe({
      next: () => {
        this.errorHandlingService.handleSuccess(
          'Download cancelled successfully',
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      },
      error: (err) => {
        this.errorHandlingService.handleError(
          '500',
          'Error cancelling download',
          err,
        );
      },
    });
  }

  refresh() {
    window.location.reload();
  }

  public getpresignedUrl(bucketKey?: string): Observable<string> {
    return this.urlHandlerService
      .getpresignedUrl(bucketKey)
      .pipe(
        catchError((error) =>
          this.errorHandlingService.handleError(
            '500',
            'Error fetching presigned URL',
            error,
          ),
        ),
      );
  }
}
