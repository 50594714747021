import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog'; // Import MatDialogModule
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ProgressModule } from '@coreui/angular';
import { AWSAuthModule } from '@drx/uilib/auth/aws-ui';
import { NgxsModule } from '@ngxs/store';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicModule } from './public/public.module';
import { CheckboxListComponent } from './securehome/checkbox-list/checkbox-list.component';
import { DashboardComponent } from './securehome/dashboard/dashboard.component';
import { DateRangePickerComponent } from './securehome/date-range-picker/date-range-picker.component';
import { DownloadCenterComponent } from './securehome/download-center/download-center.component';
import { ErrorDialogComponent } from './securehome/error-dialog/error-dialog.component';
import { PictureExpansionPanelComponent } from './securehome/picture-expansion-panel/picture-expansion-panel.component';
import { PictureSearchComponent } from './securehome/picture-search/picture-search.component';
import { ProgressBarComponent } from './securehome/progress-bar/progress-bar.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { SearchService } from './services/search-service.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatInputModule,
    NgxDatatableModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
    FormsModule,
    ErrorDialogComponent,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right', // Position of toastr notifications
      progressBar: true, // Show a progress bar
      closeButton: true, // Show a close button
      timeOut: 5000, // Duration in milliseconds (5 seconds)
    }),
    ReactiveFormsModule,
    PictureSearchComponent,
    MatButtonModule,
    CdkTableModule,
    CdkTreeModule,
    MatCheckboxModule,
    HttpClientModule,
    PictureExpansionPanelComponent,
    DateRangePickerComponent,
    CheckboxListComponent,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    DownloadCenterComponent,
    ProgressModule,
    ProgressBarComponent,
    PublicModule,
    AWSAuthModule.forRoot({
      appName: 'Data2Cloud',
      iconUrl: '',
      showFederated: true,
      // routeAfterLogin: '/secure',
      showUserNamePassword: false,
    }),
    MatTooltipModule,
    MatDialogModule, // Include MatDialogModule here
    RouterModule.forRoot([
      { path: 'dashboard', component: DashboardComponent },
    ]),
    RouterModule.forRoot([
      { path: 'download-center', component: DownloadCenterComponent }, // Define the route for Download Center
    ]),
    NgxsModule.forRoot([]),
  ],
  providers: [
    SearchService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
