import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private webSocketSubject!: WebSocketSubject<any>;

  constructor() {
    this.connectWebSocket();
  }

  connectWebSocket() {
    this.webSocketSubject = webSocket(environment.websocketUrl);

    // Handle WebSocket close event to automatically reconnect
    this.webSocketSubject.subscribe(
      () => {}, // Success handler, do nothing
      (error) => {
        console.error('WebSocket closed unexpectedly. Reconnecting...', error);
        // Attempt to reconnect after a delay
        setTimeout(
          () => this.connectWebSocket(),
          environment.websocketReconnectDelay,
        );
      },
    );
  }

  sendMessage(message: any) {
    if (this.webSocketSubject) {
      this.webSocketSubject.next(message);
    } else {
      console.error('WebSocket connection not established.');
    }
  }

  getMessages() {
    return this.webSocketSubject.asObservable();
  }
}
