import { Component } from '@angular/core';
import { PictureExpansionPanelComponent } from '../picture-expansion-panel/picture-expansion-panel.component';

// import { SensorsState } from '../sensors.state';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [PictureExpansionPanelComponent]
})
export class DashboardComponent {}

