import { Auth } from 'aws-amplify';
import { environment } from '../environments/environment';

Auth.configure({
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: environment.identityPoolId,

  // REQUIRED - Amazon Cognito Region
  region: environment.region,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: environment.userPoolId,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: environment.clientId,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: true,

  oauth: {
    // Domain name
    domain:
      environment.cognitoDomainPrefix +
      '.auth.' +
      environment.region +
      '.amazoncognito.com',
    // Authorized scopes
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn: environment.cognitoRedirectUri,
    // Sign out URL
    redirectSignOut: environment.cognitoLogoutUri,
    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    // Note that REFRESH token will only be generated when the responseType is code
    responseType: 'code',

    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features.
      AdvancedSecurityDataCollectionFlag: true,
    },
  },
});
