export const environment = {
  stage: 'dev',
  production: false,
  identityPoolId: 'eu-central-1:becdbb17-3b60-4c30-8509-b976cc2f953e',
  region: 'eu-central-1',
  assetsBucketName: '',
  userPoolId: 'eu-central-1_jfU56jj7j',
  websocketUrl: 'wss://rxn1jgpit2.execute-api.eu-central-1.amazonaws.com/prod',
  websocketReconnectDelay: 2000,
  clientId: '2ea1lv0tds4s0a68ka4iqkac0j',
  cognitoDomainPrefix: 'draexlmaier-picturetocloud',
  cognitoRedirectUri: 'https://data2cloud.draexlmaier.solutions/secure',
  cognitoLogoutUri: 'https://data2cloud.draexlmaier.solutions//public',
  filesUrl: 'assets',
  restApiUrl: 'https://rssc87qec6.execute-api.eu-central-1.amazonaws.com/prod',
};
