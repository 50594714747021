import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridColumnsService {
  private gridColsSubject = new BehaviorSubject<number>(4);
  gridCols$ = this.gridColsSubject.asObservable();

  constructor() {
    this.setGridCols(window.innerWidth);
    window.addEventListener('resize', this.onResize.bind(this));
  }

  private setGridCols(width: number) {
    if (width < 600) {
      this.gridColsSubject.next(1);
    } else if (width >= 600 && width < 960) {
      this.gridColsSubject.next(2);
    } else {
      this.gridColsSubject.next(4);
    }
  }

  private onResize(event: any) {
    this.setGridCols(event.target.innerWidth);
  }
}
