import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProgressModule } from '@coreui/angular';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressModule],
})
export class ProgressBarComponent implements OnInit {
  @Input() status: string = '';
  animated!: boolean;
  color: string = 'primary';
  variant!: 'striped' | undefined;
  label: string = '';

  ngOnInit() {
    this.updateProgressBar();
  }

  updateProgressBar() {
    if (this.status === 'PROCESSING') {
      this.color = 'primary';
      this.variant = 'striped';
      this.label = 'Running';
    } else if (this.status === 'FINISHED') {
      this.color = 'success';
      this.variant = 'striped';
      this.label = 'Completed';
    } else {
      this.color = 'danger';
      this.variant = 'striped';
      this.label = 'Cancel';
    }
  }
}
