import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface DownloadData {
  startedBy: string;
  startedAt: string;
  from: string;
  to: string;
  status: string;
  bucketKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient) {}

  fetchDownloadData(): Observable<DownloadData[]> {
    const apiUrl = environment.restApiUrl + '/images/exportcenter';

    return this.http.get<{ Items: DownloadData[] }>(apiUrl).pipe(
      map((response) => {
        // Sort the data by 'startedAt' in descending order (as strings)
        return response.Items.sort((a, b) => {
          // Convert the 'startedAt' strings to lowercase for case-insensitive sorting
          const dateA = a.startedAt.toLowerCase();
          const dateB = b.startedAt.toLowerCase();
          return dateB.localeCompare(dateA);
        });
      }),
    );
  }
}
