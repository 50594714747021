// download-file.service.ts

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeleteFileService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  deleteDownloadFile(row: any): Observable<any> {
    const apiUrl = environment.restApiUrl + '/images/deletefile';

    let queryParams = new HttpParams();
    queryParams = queryParams
      .set('startedBy', row.startedBy)
      .set('startedAt', row.startedAt)
      .set('status', 'DELETE');

    const requestBody = row;

    return this.http.post(apiUrl, requestBody, { params: queryParams }).pipe(
      switchMap(() => {
        return this.router.navigate(['/download-center']);
      }),
    );
  }
}
