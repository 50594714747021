import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-dispay-binary-stream',
  templateUrl: './display-binary-stream.component.html',
  styleUrls: ['./display-binary-stream.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
})
export class DisplayBinaryStreamComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
