import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColumnWidthService {
  private columnWidths: { [key: string]: number } = {};

  getColumnWidth(field: string): number {
    return this.columnWidths[field] || 50; // Default width is 50 if not specified
  }

  setColumnWidth(field: string, width: number): void {
    this.columnWidths[field] = width;
  }

  getAllColumnWidths(): { [key: string]: number } {
    return { ...this.columnWidths }; // Return a copy to prevent direct modification
  }

  setDefaultColumnWidth(defaultWidth: number): void {
    // Set default width for all columns
    Object.keys(this.columnWidths).forEach((key: string) => {
      this.columnWidths[key] = defaultWidth;
    });
  }
}
