import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PresignedurlHandlerService {
  constructor(private http: HttpClient) {}

  public getpresignedUrl(bucketKey?: string): Observable<string> {
    const apiUrl = environment.restApiUrl + '/images/presignedurl';
    let params = new HttpParams();

    if (bucketKey) params = params.set('bucketKey', bucketKey);
    return this.http
      .get<{ presigned_url: string }>(apiUrl, { params })
      .pipe(map((response) => response.presigned_url));
  }
}
