import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataPaginatorService {
  paramIndex!: number;
  paramSize!: number;
  private defaultParamFrom: number = 0; // Set your default values here
  private defaultParamSize: number = 25; // Set your default values here

  constructor() {}

  setPaginatorParams(paramIndex: number, paramSize: number) {
    this.paramIndex = paramIndex;
    this.paramSize = paramSize;
  }

  getparamSize(): number {
    return this.paramSize;
  }

  getparamIndex(): number {
    return this.paramIndex;
  }

  resetToDefault() {
    this.paramIndex = this.defaultParamFrom;
    this.paramSize = this.defaultParamSize;
  }
}
async function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}
