import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsModule } from '@drx/uilib/breadcrumbs';
import { NavbarModule } from '@drx/uilib/navbar';
import { TileModule } from '@drx/uilib/tile';
import { S3assetPipe } from './pipes/s3asset.pipe';

@NgModule({
  declarations: [S3assetPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TileModule,
    NavbarModule,
    BreadcrumbsModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TileModule,
    NavbarModule,
    BreadcrumbsModule,
    S3assetPipe,
  ],
})
export class SharedModule {}
