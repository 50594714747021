import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CancelFileService {
  constructor(private http: HttpClient) {}

  cancelDownloadFile(row: any): Observable<any> {
    const apiUrl = environment.restApiUrl + '/images/deletefile';

    let queryParams = new HttpParams();
    queryParams = queryParams
      .set('startedBy', row.startedBy)
      .set('startedAt', row.startedAt)
      .set('status', 'CANCEL');

    const requestBody = row;

    return this.http.post(apiUrl, requestBody, { params: queryParams });
  }
}
