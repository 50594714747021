import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ErrorHandlingService } from './services/error-handling.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showLogoutButton: boolean = true;
  showSearchButton: boolean = true;
  showDownloadButton: boolean = true;
  showNavbar: boolean = true;
  constructor(
    private router: Router,
    private errorHandlingService: ErrorHandlingService,
  ) {}
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check the current route and set showNavbar accordingly
        this.showLogoutButton = !this.router.url.includes('/public');
        this.showDownloadButton = !this.router.url.includes('/public');
        this.showSearchButton = !this.router.url.includes('/public');
        this.showNavbar = !this.router.url.includes('/public');
      }
    });
  }
  navigateToDownloadCenter() {
    this.router.navigate(['/']).then(() => {
      // Navigate back to the desired route (e.g., '/download-center')
      this.router.navigateByUrl('/download-center');
    });
  }

  navigateToSearchSide() {
    this.router.navigate(['/']).then(() => {
      // Navigate back to the desired route (e.g., '/dashboard')
      this.router.navigate(['/dashboard']);
    });
  }

  onLogoutButtonClick() {
    localStorage.clear();
    this.router.navigateByUrl('/public/logout');
  }

  title = 'PictureToCloud-Base-Frontend';
}
