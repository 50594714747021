import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExportSearchService {
  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  public displaySelectedRowsAndNavigate(
    startDate: string,
    endDate: string,
    selectedRows: any[],
  ): Observable<any> {
    const apiUrl = environment.restApiUrl + '/images/exportmetadata';
    const queryParams = {
      fromDate: startDate,
      toDate: endDate,
    };

    // Construct the request body
    const requestBody = selectedRows;

    // Make the API call
    return this.http.post(apiUrl, requestBody, { params: queryParams }).pipe(
      switchMap(() => {
        // After the API call is successful, trigger the page refresh
        return this.router.navigate(['/download-center']);
      }),
    );
  }
}
