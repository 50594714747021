<mat-grid-list [cols]="gridCols" rowHeight="130px">
  <div class="container">
    <div [hidden]="submitted">
      <form (ngSubmit)="onSubmit()" #Form="ngForm">
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="carline"
              >Carline
              <!-- <mat-radio-group>
                <mat-radio-button value="1">Text Search</mat-radio-button>
                <mat-radio-button value="2" class="custom-radio"
                  >Substring Search</mat-radio-button -->

              <!-- </mat-radio-group>-->
            </label>
            <mat-select
              class="form-control custom-select"
              [(ngModel)]="model.carline"
              [ngModelOptions]="{ standalone: true }"
              #carlines="ngModel"
              (valueChange)="onCarlineSelected($event)"
            >
              <mat-option value="" disabled selected hidden>carline</mat-option>
              <!-- Add options here -->
              <mat-option *ngFor="let carline of carline" [value]="carline.id">
                {{ carline.id }}
              </mat-option>
            </mat-select>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="workstation"
              >Workstation<mat-icon
                matTooltip="Please select Carline"
                class="info-icon"
                >info</mat-icon
              >
            </label>
            <mat-select
              class="form-control custom-select"
              [(ngModel)]="model.workstation"
              [ngModelOptions]="{ standalone: true }"
              #workstation="ngModel"
              [disabled]="!model.carline"
            >
              <mat-option value="" disabled selected hidden
                >workstation</mat-option
              >
              <mat-option
                *ngFor="let workstation of workstations"
                [value]="workstation.id"
                >{{ workstation.id }}</mat-option
              >
            </mat-select>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="selection"
              >Selection of results<mat-icon
                matTooltip="Please Enter the result list you want to search. 
                The options has searching for following data, 
                images: ['png', 'jpg', 'jpeg', 'bmp', 'tiff']

                html: ['html', 'htm', 'xhtml'],

                text: ['txt', 'doc', 'log', 'docx', 'ppt', 'pptx', 'xls',
                'xlsx', 'pdf', 'xml', 'json'],

                csv: ['csv'],

                other files are stored under other file type
                "
                class="info-icon"
                >info</mat-icon
              ></label
            >
            <mat-select
              class="form-control custom-select"
              [(ngModel)]="model.selection"
              [ngModelOptions]="{ standalone: true }"
              #ordertype="ngModel"
            >
              <mat-option value="" disabled selected hidden
                >selection of results</mat-option
              >
              <!-- Add options here -->
              <mat-option *ngFor="let item of selection" [value]="item.id">{{
                item.name
              }}</mat-option>
            </mat-select>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="ordertype">Ordertype</label>
            <mat-select
              class="form-control custom-select"
              [(ngModel)]="model.ordertype"
              [ngModelOptions]="{ standalone: true }"
              #ordertype="ngModel"
            >
              <mat-option value="" disabled selected hidden
                >ordertype</mat-option
              >
              <!-- Add options here -->
              <mat-option
                *ngFor="let orderType of ordertypes"
                [value]="orderType.id"
              >
                {{ orderType.id }}
              </mat-option>
            </mat-select>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="orderident">Orderident</label>
            <input
              type="text"
              class="form-control custom-select"
              [(ngModel)]="model.orderident"
              [ngModelOptions]="{ standalone: true }"
              #orderident="ngModel"
              placeholder="orderident"
            />
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="file"
              >Filename<mat-icon
                matTooltip="Please Enter the Filename the File had in the corresponding Zipfile!"
                class="info-icon"
                >info</mat-icon
              ></label
            >
            <input
              type="text"
              class="form-control custom-select"
              [(ngModel)]="model.file"
              [ngModelOptions]="{ standalone: true }"
              #file="ngModel"
              placeholder="filename"
            />
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="form-group">
            <label class="grayed-label" for="filename"
              >OriginalFilename<mat-icon
                matTooltip="Please enter the orginal Filename of the Zip File or single File"
                class="info-icon"
                >info</mat-icon
              ></label
            >
            <input
              type="text"
              class="form-control custom-select"
              [(ngModel)]="model.originalFilename"
              [ngModelOptions]="{ standalone: true }"
              #filename="ngModel"
              placeholder="filename"
            />
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="from-control custom-div">
            <label class="grayed-label"> Date Range</label>
            <app-date-range-picker
              class="from control custom-div"
              (dateRangeSelected)="handleDateRangeSelected($event)"
            >
            </app-date-range-picker>
          </div> </mat-grid-tile
        ><mat-grid-tile></mat-grid-tile><mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>
          <button type="button" class="submit-button" (click)="onSearch()">
            <i class="fa-solid fa-magnifying-glass white-icon"></i
            ><span> Search</span>
          </button>
        </mat-grid-tile>
        <mat-grid-tile>
          <button type="button" class="clear-button" (click)="clearFilter()">
            <i class="fa-solid fa-delete-left white-icon"></i
            ><span> Clear Filter</span>
          </button>
        </mat-grid-tile>
      </form>
    </div>
  </div>
</mat-grid-list>
<app-alert-info></app-alert-info>
