<mat-accordion [multi]="true">
  <mat-expansion-panel #searchPanel [expanded]="isSearchPanelExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="centered-title">
        Data to Cloud Search Attributes
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-card>
      <mat-card-content class="custom-card">
        <app-picture-search
          (searchClicked)="onSearchClicked()"
          (searchResultsChanged)="onSearchResultsChanged($event)"
        ></app-picture-search>
      </mat-card-content>
    </mat-card>
  </mat-expansion-panel>

  <mat-expansion-panel #resultPanel [expanded]="isResultPanelExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="centered-title">
        Result List Search
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-checkbox-list [searchResultsData]="searchResults"></app-checkbox-list>
  </mat-expansion-panel>
</mat-accordion>
