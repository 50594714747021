import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface CarlineTypesResponse {
  items: any[]; // Adjust the type based on your actual response
}

@Injectable({
  providedIn: 'root',
})
export class CarlineService {
  constructor(private http: HttpClient) {}

  fetchCarlineTypes(): Observable<CarlineTypesResponse> {
    const apiUrl = environment.restApiUrl + '/images/getcarline';
    return this.http.get<CarlineTypesResponse>(apiUrl);
  }
}
