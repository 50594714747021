<h2 mat-dialog-title class="center-title">Image Data</h2>

<mat-dialog-content class="custom-dialog-content">
  <div class="data-container">
    <p class="left">Key: {{ data.key }}</p>
    <p class="right">Filename: {{ data.filename }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-content class="custom-dialog-content">
  <div class="data-container">
    <p class="left">Carline: {{ data.carline }}</p>
    <p class="center">CreationDate: {{ data.creationdate }}</p>
    <p class="right">Workstation: {{ data.workstation }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-content class="custom-dialog-content">
  <div class="data-container">
    <p class="left">OrderIdent: {{ data.orderident }}</p>
    <p class="right">OrderType: {{ data.ordertype }}</p>
  </div>

  <div class="image-container">
    <img [src]="data.imageData" alt="Image" />
  </div>
</mat-dialog-content>

<mat-dialog-actions class="custom-dialog-actions">
  <button mat-button [mat-dialog-close] cdkFocusInitial class="close-button">
    <i class="fa-regular fa-rectangle-xmark"></i>
    Close
  </button>
</mat-dialog-actions>
