<form [formGroup]="rangeForm">
  <!-- Bind the FormGroup to a form element -->
  <mat-card class="example-form">
    <mat-form-field>
      <mat-label>Enter the Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </mat-card>
</form>
