import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductList } from '../securehome/checkbox-list/checkbox-list.component';

@Injectable({
  providedIn: 'root',
})
export class CheckboxService {
  private selectedRowsSubject = new BehaviorSubject<ProductList[]>([]);
  private _searchResults: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedRows$ = this.selectedRowsSubject.asObservable();
  private isButtonDisabled: boolean = true;
  private selectAllSubject = new BehaviorSubject<boolean>(false);
  selectAll$ = this.selectAllSubject.asObservable();

  getSelectedRows(): ProductList[] {
    return this.selectedRowsSubject.value;
  }

  setSelectedRows(rows: ProductList[]): void {
    this.selectedRowsSubject.next(rows);
  }

  getSelectAll(): boolean {
    return this.selectAllSubject.getValue();
  }

  setSelectAll(selectAll: boolean): void {
    this.selectAllSubject.next(selectAll);
  }

  getButtonDisabled(): boolean {
    return this.isButtonDisabled;
  }

  getsearchResults(): Observable<any> {
    return this._searchResults.asObservable();
  }

  setSearchResults(results: any): void {
    this._searchResults.next(results);
  }

  setButtonDisabled(value: boolean) {
    this.isButtonDisabled = value;
  }

  clearSelectedRows(): void {
    this.selectedRowsSubject.next([]);
  }

  isRowSelected(row: ProductList): boolean {
    // Check if the provided row is already selected
    const selectedRows = this.selectedRowsSubject.value;
    return selectedRows.some((selectedRow) => {
      // Compare the properties that uniquely identify a row, e.g., workstation and orderident
      return selectedRow.filename === row.filename;
    });
  }

  removeRow(row: ProductList): void {
    // Remove a row by its unique identifier (e.g., filename)
    const selectedRows = this.selectedRowsSubject.value;
    const updatedRows = selectedRows.filter((selectedRow) => {
      // Keep all rows that don't match the provided row's unique identifier
      return selectedRow.filename !== row.filename;
    });
    this.selectedRowsSubject.next(updatedRows);
  }
}
