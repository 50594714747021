import { Injectable } from '@angular/core';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { DataRangeService } from './data-range.service';
import { ErrorHandlingService } from './error-handling.service';
import { LoadingService } from './loading.service';
import { WebSocketService } from './web-socket.service';

@Injectable()
export class SearchService {
  constructor(
    private webSocketService: WebSocketService,
    private loadingService: LoadingService,
    private dataService: DataRangeService,
    private errorHandlingService: ErrorHandlingService,
  ) {}

  search(params: {
    workstation?: string;
    file?: string;
    carline?: string;
    ordertype?: string;
    orderident?: string;
    originalFilename?: string;
    startDate?: string;
    endDate?: string;
    selection?: string;
    page?: number;
    pageSize?: number;
  }) {
    const message = {
      action: 'listMetadata',
      params: params,
    };

    if (params.startDate && params.endDate) {
      this.dataService.setDates(params.startDate, params.endDate);
    }

    this.loadingService.isLoadingSubject$.next(true);
    this.webSocketService.sendMessage(message);

    return this.webSocketService.getMessages().pipe(
      tap((msg) => {
        console.log('Received message:', msg);
      }),
      catchError((error) => {
        this.errorHandlingService.handleError(
          '500',
          'Error from Server',
          error,
        );
        return throwError(error);
      }),
      finalize(() => {
        this.loadingService.isLoadingSubject$.next(false);
      }),
    );
  }
}
