// ordertype.service.ts

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface OrderTypeResponse {
  items: any[]; // Adjust the type based on your actual response
}

@Injectable({
  providedIn: 'root',
})
export class OrderTypeService {
  constructor(private http: HttpClient) {}

  fetchOrderTypes(): Observable<OrderTypeResponse> {
    const apiUrl = environment.restApiUrl + '/images/getordertype';
    return this.http.get<OrderTypeResponse>(apiUrl);
  }
}
