<h2 mat-dialog-title class="center-title">
  Error - Handling Data in Data2Cloud
  <div class="info-icon-wrapper">
    <mat-icon
      matTooltip="Solution for the problem could be to search with a smaller Data Range"
      class="info-icon"
      >info</mat-icon
    >
  </div>
</h2>

<mat-dialog-content class="custom-dialog-content">
  <p class="error-message">{{ data.genericErrorMessage }}</p>
  <p *ngIf="data.backendMessage" class="backend-message">
    Message from Backend: {{ data.backendMessage }}
  </p>
</mat-dialog-content>
<mat-dialog-actions class="custom-dialog-actions">
  <button
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="close-button"
  >
    <span> Close</span>
  </button>
</mat-dialog-actions>
