<nav class="navbar navbar-bg-primary" *ngIf="showNavbar">
  <div class="container">
    <div class="navbar-left">
      <img src="/assets/img/drx-logo-white.svg" class="logo" alt="Logo" />
    </div>
    <div class="navbar-right">
      <button
        mat-button
        class="navbar-button"
        *ngIf="showSearchButton"
        (click)="navigateToSearchSide()"
      >
        <mat-icon>search</mat-icon>
        <span>Search</span>
      </button>
      <button
        mat-button
        class="navbar-button"
        *ngIf="showDownloadButton"
        (click)="navigateToDownloadCenter()"
      >
        <mat-icon>save_alt</mat-icon>
        <span>Download Center</span>
      </button>
      <button
        mat-button
        class="navbar-button"
        *ngIf="showLogoutButton"
        (click)="onLogoutButtonClick()"
      >
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
