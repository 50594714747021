import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent, LogoutComponent } from '@drx/uilib/auth/aws-ui';
import { PublicComponent } from './public.component';

export const publicRoutes: Routes = [
  {
    path: 'public',
    component: PublicComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LogoutComponent },

      { path: '**', redirectTo: 'login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(publicRoutes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
// export const PublicRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(
//   routes
// );
