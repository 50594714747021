<!-- progress-bar.component.html -->
<div class="progress">
  <ng-container *ngIf="status === 'PROCESSING'; else finishedOrError">
    <div
      class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
      [style.width]="animated ? '100%' : '100%'"
      [attr.aria-valuenow]="animated ? 100 : 50"
      aria-valuemin="0"
      aria-valuemax="100"
      [style.width.px]="150"
    >
      {{ label }}
    </div>
  </ng-container>

  <ng-template #finishedOrError>
    <div
      class="progress-bar bg-{{ color }}"
      [style.width]="animated ? '100%' : '100%'"
      [attr.aria-valuenow]="animated ? 100 : 50"
      aria-valuemin="0"
      aria-valuemax="100"
      [style.width.px]="150"
    >
      {{ label }}
    </div>
  </ng-template>
</div>
