import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertInfoService } from './service/alert-info.service';
import { AlertInterface } from './type/alert-info.interface';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AlertComponent implements OnInit {
  alert?: AlertInterface;
  timeoutId?: number;

  constructor(
    private alertService: AlertInfoService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.alertService.getAlert().subscribe((alert) => {
      this.alert = alert;
      this.showAlertPopup(alert.text);
      this.resetTimer();
    });
  }

  showAlertPopup(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 5000, // Adjust the duration as needed
      verticalPosition: 'top', // You can adjust the position
      horizontalPosition: 'center',
      // You can adjust the position
    });
  }

  resetTimer(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => {
      this.alert = undefined;
    }, 6000);
  }
}
