import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  toastrConfig: Partial<IndividualConfig> = {
    timeOut: 2500, // Duration in milliseconds (2.5 seconds)
    positionClass: 'toast-bottom-right',
    closeButton: true, // Show close button
    tapToDismiss: true, // Click toast to dismiss
    toastClass: 'custom-toast',
  };

  private currentToastId: number | null = null;

  constructor(private toastr: ToastrService) {}

  private clearCurrentToast() {
    if (this.currentToastId !== null) {
      this.toastr.clear(this.currentToastId);
      console.log(`Cleared toast with ID: ${this.currentToastId}`);
      this.currentToastId = null;
    }
  }

  handleError(
    statusCode: string,
    message: string,
    error: any,
  ): Observable<never> {
    console.log(error);

    // Clear the currently displayed toast if it exists
    this.clearCurrentToast();

    // Check if the error is an instance of HttpErrorResponse
    let toast;
    if (error instanceof HttpErrorResponse) {
      toast = this.toastr.error(
        error.message,
        'ERROR ' + statusCode,
        this.toastrConfig,
      );
    } else {
      // Client-side or network error occurred
      toast = this.toastr.error(
        message,
        'ERROR ' + statusCode,
        this.toastrConfig,
      );
    }

    // Set the current toast ID
    if (toast && toast.toastId !== undefined) {
      this.currentToastId = toast.toastId;
      console.log(`New toast created with ID: ${this.currentToastId}`);
    }

    // Rethrow the error
    return throwError(error);
  }

  handleSuccess(message: string, title: string = 'Success'): void {
    // Clear the currently displayed toast if it exists
    this.clearCurrentToast();

    const toast = this.toastr.success(message, title, this.toastrConfig);
    if (toast && toast.toastId !== undefined) {
      this.currentToastId = toast.toastId;
      console.log(`New success toast created with ID: ${this.currentToastId}`);
    }
  }
}
