<div>
  <mat-card class="checkbox-header">
    <mat-card-content>
      <div class="example-button-row">
        <button
          (click)="displaySelectedRows()"
          type="submit"
          class="submit-button"
          [disabled]="checkboxService.getButtonDisabled()"
        >
          <i class="fas fa-download download-icon"></i>
          <span> Download</span>
        </button>
        <div
          class="info-icon"
          *ngIf="checkboxService.getButtonDisabled()"
          matTooltip="{{ getTooltip() }}"
        >
          <i class="fas fa-info-circle"></i>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <!-- Table content here -->
    <div *ngIf="searchResults">
      <mat-paginator
        [length]="searchResults.total || 0"
        [pageIndex]="dataPaginatorService.getparamIndex()"
        [pageSize]="dataPaginatorService.getparamSize()"
        [pageSizeOptions]="pageSizeOptions"
        (page)="updateResultsData($event)"
        class="paginator-left"
      ></mat-paginator>
    </div>
  </mat-card>
  <div class="loading-indicator" *ngIf="isLoading$ | async">
    <mat-spinner diameter="50"></mat-spinner>
    <p>Loading...</p>
  </div>
  <div *ngIf="(isLoading$ | async) === false">
    <ngx-datatable
      (resize)="onColumnResize($event)"
      class="my-table material product-search fixed-header striped shadow-none animation-fade-in custom-ngx-datatable"
      [rows]="pagedSearchResults.data"
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="0"
      [rowHeight]="'auto'"
      [count]="searchResults?.total || 0"
      [externalPaging]="true"
      (page)="updateResultsData($event)"
    >
      <!-- Checkbox Column -->
      <ngx-datatable-column
        class="scrolling-column"
        name="selectAll"
        [sortable]="false"
        [canAutoResize]="false"
        [width]="50"
      >
        <ng-template ngx-datatable-header-template let-value="value">
          <mat-checkbox
            [(ngModel)]="selectAll"
            (change)="selectAllRows()"
          ></mat-checkbox>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row">
          <mat-checkbox
            class="scrolling-column"
            (click)="$event.stopPropagation(); selectRow(row, $event)"
            [checked]="isRowSelected(row)"
          ></mat-checkbox>
        </ng-template>
      </ngx-datatable-column>

      <!-- Filename Column -->
      <ngx-datatable-column
        *ngFor="let column of columns; let i = index"
        name="{{ column.field }}"
        [prop]="column.field"
        [sortable]="false"
        [canAutoResize]="true"
        [width]="getColumnWidth(column.field)"
        [headerTemplate]="customHeaderTemplate"
        style="white-space: pre-wrap; height: 90px; overflow: auto"
      >
        >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div
            [ngStyle]="{
              'text-align': isCenteredColumn(column.field) ? 'center' : 'left'
            }"
          >
            {{ row[column.field] }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- Button preview Column -->
      <ngx-datatable-column
        name="preview"
        [headerTemplate]="customHeaderTemplate"
        [sortable]="false"
        [canAutoResize]="false"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button
            *ngIf="
              allowedFileExtensions.includes(row.fileextension);
              else notAllowed
            "
            type="button"
            class="submit-button"
            (click)="showData(row)"
          >
            <i class="fa-solid fa-magnifying-glass magnifying-glass-icon"></i>
            <span> Show Data</span>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div *ngIf="searchResults">
      <mat-paginator
        [length]="searchResults.total || 0"
        [pageIndex]="dataPaginatorService.getparamIndex()"
        [pageSize]="dataPaginatorService.getparamSize()"
        [pageSizeOptions]="pageSizeOptions"
        (page)="updateResultsData($event)"
      ></mat-paginator>
    </div>

    <ng-template #noRows>
      <div class="py-2 animation-fade-in">
        <div class="text-center">No validation results found</div>
      </div>
    </ng-template>

    <ng-template #customHeaderTemplate let-column="column">
      <div class="header-resizeable">
        {{ column.name }}
      </div>
    </ng-template>

    <ng-template #customHeaderTemplateSelect let-column="column">
      <div class="custom-header-select"></div>
    </ng-template>

    <ng-template #notAllowed>
      <button
        type="button"
        class="submit-button"
        [matTooltip]="'Show data not allowed for this File extension'"
      >
        <i class="fa-solid fa-info-circle info-circle-icon"></i>
        <span> Show Data</span>
      </button>
    </ng-template>
  </div>
</div>
