import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class DateRangePickerComponent {
  @Output() dateRangeSelected: EventEmitter<Date[]> = new EventEmitter<
    Date[]
  >();
  rangeForm: FormGroup;

  constructor(private fb: FormBuilder) {
    // Initialize the form with empty values for start and end dates
    this.rangeForm = this.fb.group({
      start: [''],
      end: [''],
    });

    // Listen for changes in the form controls and emit the selected date range
    this.rangeForm.valueChanges.subscribe((formValues) => {
      const startDate = formValues.start;
      const endDate = formValues.end;

      // Check if both start and end dates are selected
      if (startDate && endDate) {
        // Adjust the time portion of the dates
        const startOfDay = new Date(startDate);
        startOfDay.setHours(0, 0, 0, 0);
        const utcStartDate = this.toUTC(startOfDay);
        const endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);
        const utcEndDate = this.toUTC(endOfDay);
        const dateRange: Date[] = [utcStartDate, utcEndDate];
        this.dateRangeSelected.emit(dateRange);
      }
    });
  }

  toUTC(date: Date): Date {
    // Convert a date to UTC by setting its time zone offset to 0
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  clearDateRange() {
    // Clear the start and end date values and reset the form
    this.rangeForm.get('start')?.setValue(null);
    this.rangeForm.get('end')?.setValue(null);
    this.rangeForm.reset();
  }
}
