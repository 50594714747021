import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DisplayBinaryStreamComponent } from 'src/app/securehome/display-binary-stream/display-binary-stream.component';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisplayedFilesService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private loadingService: LoadingService,
  ) {}

  showData(row: any): Observable<any> {
    this.loadingService.isLoadingSubject$.next(true);

    const apiUrl = environment.restApiUrl + '/images/retrievefilesdisplayed';

    let queryParams = new HttpParams();
    queryParams = queryParams
      .set('key', row.key)
      .set('filename', row.filename)
      .set('file', row.file);

    return this.http.get(apiUrl, { params: queryParams });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.toLocaleString('en-US', {
      weekday: 'short',
      timeZone: 'UTC',
    });
    const month = date.toLocaleString('en-US', {
      month: 'short',
      timeZone: 'UTC',
    });
    const dayOfMonth = date.getUTCDate(); // Use getUTCDate to ensure UTC
    const year = date.getUTCFullYear(); // Use getUTCFullYear to ensure UTC
    const hours = date.getUTCHours().toString().padStart(2, '0'); // Use getUTCHours to ensure UTC
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Use getUTCMinutes to ensure UTC
    const seconds = date.getUTCSeconds().toString().padStart(2, '0'); // Use getUTCSeconds to ensure UTC

    return `${day} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}:${seconds}`;
  }

  openDialog(data: any, row: any): void {
    const dialogRef = this.dialog.open(DisplayBinaryStreamComponent, {
      width: '1800px',
      disableClose: false,
      data: {
        key: data.key,
        filename: data.filename,
        creationdate: this.formatDate(row.creationdate),
        carline: row.carline,
        workstation: row.workstation,
        ordertype: row.ordertype,
        orderident: row.orderident,
        imageData: data.pre_signed_url,
      },
    });

    this.loadingService.isLoadingSubject$.next(false);
  }

  openErrorDialogDisplay(row: any): void {
    const dialogRef = this.dialog.open(DisplayBinaryStreamComponent, {
      width: '1800px',
      disableClose: false,
      data: {
        key: row.key,
        filename: row.file,
        creationdate: row.creationdate,
        carline: row.carline,
        workstation: row.workstation,
        ordertype: row.ordertype,
        orderident: row.orderident,
        imageData: '',
      },
    });
    this.loadingService.isLoadingSubject$.next(false);
  }
}
