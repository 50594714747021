import { Pipe, PipeTransform } from '@angular/core';
import { Cache, Storage } from 'aws-amplify';

@Pipe({
  name: 's3asset',
})
export class S3assetPipe implements PipeTransform {
  transform(key: string): Promise<string> {
    if (!key) {
      return Promise.resolve(key);
    }
    return this.getImageUrlFromCache(key) as Promise<string>;
  }

  async getImageUrlFromCache(key: string): Promise<string> {
    // See https://github.com/aws-amplify/amplify-js/issues/1342
    const cachedImage = Cache.getItem(key);
    if (cachedImage) {
      return cachedImage;
    }
    const expireDurationInSeconds = 60 * 60;
    const expireDurationInMilliSeconds = expireDurationInSeconds * 1000;
    const expiresDateInMilliSeconds =
      new Date().getTime() + expireDurationInMilliSeconds;
    const url = await Storage.get(key, { expires: expireDurationInSeconds });
    Cache.setItem(key, url, { expires: expiresDateInMilliSeconds });
    return url as string;
  }
}
