import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent {
  versionString = '0.0.1';
  stageName = environment.stage;
  appImage = 'assets/img/app-img.jpg';
  constructor() {}
}
